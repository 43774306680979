import SyncIcon from "@mui/icons-material/Sync";
import { Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import { Loading } from "../global/Loading";

const AddPoolForm = ({ exchangeSlug, onSubmit, onClose, mode }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [jwt] = useLocalState(null, "jwt");
  const [loading, setLoading] = useState(false);
  const [requestTrigger, setRequestTrigger] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [poolName, setPoolName] = useState("");
  const [matchingPools, setMatchingPools] = useState([]);

  const handleInputChange = (e) => {
    setErrorMessage("");
    const { value } = e.target;
    setPoolName(value);
  };

  const handleAddPoolsWithSync = async () => {
    if (matchingPools.length > 0) {
      await handleSubmit(matchingPools);
    }
  };

  const handleSubmit = async (pools) => {
    setLoading(true);
    if (pools.length > 0) {
      fetch(`${backendUrl}/exchanges/pools/createPools`, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + jwt,
        },
        body: JSON.stringify({
          pools: pools,
          exchangeSlug: exchangeSlug,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            if (response.status === 404) {
              throw new Error("No pools were created");
            } else {
              throw new Error("There was an error processing your request.");
            }
          }
          return response.json();
        })
        .then((data) => {
          onSubmit();
          setLoading(false);
          setRequestTrigger(!requestTrigger);
        })
        .catch((error) => {
          setLoading(false);
          console.error("There was an error!", error.message);
        });
    }
  };

  const handleSync = async () => {
    if (poolName !== "") {
      try {
        setLoading(true);
        setErrorMessage("");

        if (mode === "addPool") {
          if (poolName.startsWith("pool1")) {
            await handleSubmit([poolName]);
          } else {
            throw new Error("PoolId should start with pool1");
          }
        } else if (mode === "syncPools") {
          const response = await fetch(
            "https://raw.githubusercontent.com/cardano-community/pool_groups/refs/heads/main/pool_clusters.json"
          );
          if (!response.ok) {
            throw new Error("Failed to fetch pool data");
          }
          const data = await response.json();

          const matchingPoolKeys = Object.keys(data).filter(
            (key) => data[key].adastat === poolName
          );

          if (matchingPoolKeys.length > 0) {
            setMatchingPools(matchingPoolKeys);
          } else {
            setErrorMessage("No pools found with the provided AdaStat alias");
          }
        }
      } catch (e) {
        console.error("Error syncing pool:", e);
        setErrorMessage(e.message);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          mb: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TextField
          fullWidth
          label={mode === "addPool" ? "Pool Id" : "AdaStat Alias"}
          value={poolName}
          name="poolName"
          id="poolName"
          onChange={handleInputChange}
          margin="normal"
          required
          disabled={loading}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "5px",
        }}
      >
        {errorMessage && (
          <Box sx={{ color: "red", marginTop: 2, marginBottom: 2 }}>
            {errorMessage}
          </Box>
        )}
        {mode === "addPool" && (
          <>
            <CustomButton
              type={"create"}
              buttonText={"Add"}
              onClick={handleSync}
              disabled={loading}
            />
          </>
        )}
        {mode === "syncPools" && (
          <>
            <Box sx={{ color: "gray", marginTop: 2, marginBottom: 2 }}>
              *existing pools will not be synced / created
            </Box>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Loading />
              </Box>
            )}
            <Box display={"flex"} gap={"5px"} mt={"20px"}>
              <CustomButton
                color={"green"}
                icon={SyncIcon}
                buttonText={"Sync"}
                onClick={handleSync}
                disabled={loading}
              />
              {matchingPools.length > 0 && (
                <CustomButton
                  type={"create"}
                  buttonText={`Add ${matchingPools.length} Pools`}
                  onClick={handleAddPoolsWithSync}
                  disabled={loading}
                />
              )}
            </Box>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AddPoolForm;
