import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Icon } from "@mui/material";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { colorGrid } from "../../theme";
import { useExchangeStatus } from "../../utils/contexts/entities/exchanges/exchangeStatusContext";
import CustomTabsTable from "../global/CustomTabsTable";
import TruncateWords from "../global/TruncatedWords";

const EntitiesTable = ({ initialEntities }) => {
  const [currentTab, setCurrentTab] = useState("exchanges");
  const [columnOrder, setColumnOrder] = useState([]);
  const [entities, setEntities] = useState(initialEntities || []);
  const { globalUpdate, exchangeUpdate, joinExchangeRoom, leaveExchangeRoom } =
    useExchangeStatus();

  useEffect(() => {
    if (globalUpdate && globalUpdate.statusUpdate) {
      const updatedEntities = entities.map((entity) => {
        if (entity.slug === globalUpdate.exchangeSlug) {
          return {
            ...entity,
            exchange: {
              ...entity.exchange,
              exchangeStatus: {
                ...entity.exchange.exchangeStatus,
                depositAllowed: globalUpdate.statusUpdate.depositAllowed,
                withdrawalAllowed: globalUpdate.statusUpdate.withdrawalAllowed,
              },
            },
          };
        }
        return entity;
      });
      setEntities(updatedEntities);
    }
  }, [globalUpdate]);

  useEffect(() => {
    setColumnOrder(tabsFunctions[currentTab].order);
  }, [currentTab]);

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);
  };

  const tabsFunctions = {
    all: {
      func: function (data) {
        return data;
      },
      label: "All",
      color: "default",
    },
    exchanges: {
      func: function (data) {
        return data.filter((entity) => entity.entityType === "exchange");
      },
      label: "Exchanges",
      color: "green",
      sortModel: [
        {
          field: "baseVolumes",
          sort: "desc",
        },
      ],
    },
    wallets: {
      func: function (values) {
        return values.filter((entity) => entity.entityType === "wallet");
      },
      label: "Wallets",
      color: "blue",
    },
  };

  const getColumnOrder = () => {
    switch (currentTab) {
      case "exchanges":
        return [
          {
            field: "name",
            headerName: "Name",
            cellClassName: "name-column--cell",
            width: 170,
            type: "string",
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={params.row[params.row.entityType].logo}
                    alt="logo"
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <Box>
                    <Link
                      to={`/entities/${params.row.slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {params.formattedValue}
                    </Link>
                  </Box>
                </Box>
              );
            },
          },
          {
            field: "baseVolumes",
            headerName: "Base Volume",
            type: "number",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              value = value.row[value.row.entityType];
              if (value.baseVolumes) {
                if (value.baseVolumes.length > 0) {
                  let mostRecent = value.baseVolumes.sort(
                    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                  )[0];
                  return mostRecent.volume;
                }
              }
              return 0;
            },
            valueFormatter: (value) => {
              return value.value.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              });
            },
            width: 120,
          },
          {
            field: "targetVolumes",
            headerName: "Target Volume",
            type: "number",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              value = value.row[value.row.entityType];
              if (value.targetVolumes) {
                if (value.targetVolumes.length > 0) {
                  let mostRecent = value.targetVolumes.sort(
                    (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                  )[0];
                  return mostRecent.volume;
                }
              }
              return 0;
            },
            valueFormatter: (value) => {
              return value.value.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              });
            },
            width: 120,
          },
          {
            field: "trustScore",
            headerName: "Trust Score",
            type: "string",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              return value.row[value.row.entityType].trustScore;
            },
            renderCell: (params) => {
              return (
                <Box
                  component="span"
                  ml={1}
                  bgcolor={() => {
                    return params.formattedValue
                      ? colorGrid[params.formattedValue].background
                      : colorGrid["gray"].background;
                  }}
                  color={() => {
                    return params.formattedValue
                      ? colorGrid[params.formattedValue].text
                      : colorGrid["gray"].text;
                  }}
                  borderRadius="4px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={0.5}
                  py={0.2}
                  fontWeight={700}
                  fontSize={""}
                >
                  {params.formattedValue ?? "no data"}
                </Box>
              );
            },
          },
          {
            field: "assets",
            headerName: "Trading Pairs",
            valueGetter: (value) => {
              return value.row[value.row.entityType].assets;
            },
            valueFormatter: (value) => {
              if (value.value) {
                let values = value.value.map((asset) => {
                  let assetName = asset.name;
                  if (asset.name.includes("_UNKNOWN")) {
                    assetName = asset.name.split("_")[0];
                  }
                  return {
                    assetName: assetName,
                    assetPolicyId: asset.policyId,
                  };
                });
                return values;
              }
            },
            flex: 1,
            renderCell: (params) => {
              if (params.formattedValue) {
                return (
                  <TruncateWords
                    words={params.formattedValue.map(
                      (value) => value.assetName
                    )}
                    separator={" "}
                    displayCount={3}
                  />
                );
              }
            },
          },
          {
            field: "hasNativeAssets",
            headerName: "Native Assets",
            type: "boolean",
            flex: 1,
            valueGetter: (value) => {
              value = value.row[value.row.entityType];
              if (value) {
                return value.hasNativeAssets;
              }
              return false;
            },
          },
          {
            field: "isCentralized",
            headerName: "Centralized",
            type: "boolean",
            flex: 1,
            valueGetter: (value) => {
              value = value.row[value.row.entityType];
              if (value) {
                return value.isCentralized;
              }
              return false;
            },
          },
          {
            field: "channels",
            headerName: "Channels",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              return value.row.channels.length;
            },
            renderCell: (params) => {
              if (params.value === 0) {
                return (
                  <Icon>
                    <ErrorOutlineIcon />
                  </Icon>
                );
              } else {
                return params.value;
              }
            },
          },
          {
            field: "deposit",
            headerName: "Deposit",
            type: "string",
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
              const exchangeStatus = params.row.exchange?.exchangeStatus;
              return exchangeStatus
                ? exchangeStatus.depositAllowed
                  ? "online"
                  : "offline"
                : "no data";
            },
            renderCell: (params) => {
              const text = params.value;
              const color =
                text === "online"
                  ? "green"
                  : text === "offline"
                  ? "red"
                  : "gray";
              return (
                <Box
                  component="span"
                  ml={1}
                  bgcolor={colorGrid[color].background}
                  color={colorGrid[color].text}
                  borderRadius="4px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={0.5}
                  py={0.2}
                  fontWeight={700}
                  fontSize={""}
                >
                  {text}
                </Box>
              );
            },
            flex: 1,
          },
          {
            field: "withdrawal",
            headerName: "Withdrawal",
            type: "string",
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
              const exchangeStatus = params.row.exchange?.exchangeStatus;
              return exchangeStatus
                ? exchangeStatus.withdrawalAllowed
                  ? "online"
                  : "offline"
                : "no data";
            },
            renderCell: (params) => {
              const text = params.value;
              const color =
                text === "online"
                  ? "green"
                  : text === "offline"
                  ? "red"
                  : "gray";
              return (
                <Box
                  component="span"
                  ml={1}
                  bgcolor={colorGrid[color].background}
                  color={colorGrid[color].text}
                  borderRadius="4px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={0.5}
                  py={0.2}
                  fontWeight={700}
                  fontSize={""}
                >
                  {text}
                </Box>
              );
            },
            flex: 1,
          },
        ];
      case "wallets":
        return [
          {
            field: "name",
            headerName: "Name",
            cellClassName: "name-column--cell",
            width: 170,
            type: "string",
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={params.row[params.row.entityType].logo}
                    alt="logo"
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <Box>
                    <Link
                      to={`/entities/${params.row.slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {params.formattedValue}
                    </Link>
                  </Box>
                </Box>
              );
            },
          },
          {
            field: "trustScore",
            headerName: "Trust Score",
            type: "string",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              return value.row[value.row.entityType].trustScore;
            },
            renderCell: (params) => {
              return (
                <Box
                  component="span"
                  ml={1}
                  bgcolor={() => {
                    return params.formattedValue
                      ? colorGrid[params.formattedValue].background
                      : colorGrid["gray"].background;
                  }}
                  color={() => {
                    return params.formattedValue
                      ? colorGrid[params.formattedValue].text
                      : colorGrid["gray"].text;
                  }}
                  borderRadius="4px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={0.5}
                  py={0.2}
                  fontWeight={700}
                  fontSize={""}
                >
                  {params.formattedValue ?? "no data"}
                </Box>
              );
            },
          },
          {
            field: "hasNativeAssets",
            headerName: "Native Assets",
            type: "boolean",
            flex: 1,
            valueGetter: (value) => {
              value = value.row[value.row.entityType];
              if (value) {
                return value.hasNativeAssets;
              }
              return false;
            },
          },
          {
            field: "channels",
            headerName: "Channels",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              return value.row.channels.length;
            },
            renderCell: (params) => {
              if (params.value === 0) {
                return (
                  <Icon>
                    <ErrorOutlineIcon />
                  </Icon>
                );
              } else {
                return params.value;
              }
            },
          },
          {
            field: "hasStaking",
            headerName: "Staking",
            type: "boolean",
            width: 100,
            flex: 1,
            valueGetter: (value) => {
              value = value.row[value.row.entityType];
              if (value) {
                return value.hasNativeAssets;
              }
              return false;
            },
          },
        ];
      default:
        return [
          {
            field: "name",
            headerName: "Name",
            cellClassName: "name-column--cell",
            width: 170,
            type: "string",
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={params.row[params.row.entityType].logo}
                    alt="logo"
                    style={{
                      width: "25px",
                      height: "25px",
                      marginRight: "10px",
                    }}
                  />
                  <Box>
                    <Link
                      to={`/entities/${params.row.slug}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      {params.formattedValue}
                    </Link>
                  </Box>
                </Box>
              );
            },
          },
          {
            field: "trustScore",
            headerName: "Trust Score",
            type: "string",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              return value.row[value.row.entityType].trustScore;
            },
            renderCell: (params) => {
              return (
                <Box
                  component="span"
                  ml={1}
                  bgcolor={() => {
                    return params.formattedValue
                      ? colorGrid[params.formattedValue].background
                      : colorGrid["gray"].background;
                  }}
                  color={() => {
                    return params.formattedValue
                      ? colorGrid[params.formattedValue].text
                      : colorGrid["gray"].text;
                  }}
                  borderRadius="4px"
                  height="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  px={0.5}
                  py={0.2}
                  fontWeight={700}
                  fontSize={""}
                >
                  {params.formattedValue ?? "no data"}
                </Box>
              );
            },
          },
          {
            field: "hasNativeAssets",
            headerName: "Native Assets",
            type: "boolean",
            flex: 1,
            valueGetter: (value) => {
              value = value.row[value.row.entityType];
              if (value) {
                return value.hasNativeAssets;
              }
              return false;
            },
          },
          {
            field: "channels",
            headerName: "Channels",
            headerAlign: "center",
            align: "center",
            valueGetter: (value) => {
              return value.row.channels.length;
            },
            renderCell: (params) => {
              if (params.value === 0) {
                return (
                  <Icon>
                    <ErrorOutlineIcon />
                  </Icon>
                );
              } else {
                return params.value;
              }
            },
          },
        ];
    }
  };

  const columns = getColumnOrder();

  return (
    <CustomTabsTable
      data={entities}
      columns={columns}
      name={"entities"}
      columnOrder={columnOrder}
      tabs={tabsFunctions}
      defaultValue={"exchanges"}
      sortModel={tabsFunctions.exchanges.sortModel}
      onTabChange={handleTabChange}
      toolbar={{
        exportOptions: {
          hasNativeAssets: {
            name: "Has Native Assets",
            value: true,
          },
          isCentralized: {
            name: "Centralized",
            value: true,
          },
          includeAssets: {
            name: "Export Assets",
            value: false,
          },
        },
      }}
    />
  );
};

export default EntitiesTable;
