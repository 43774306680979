import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box, Icon, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";
import CustomTabsTable from "../global/CustomTabsTable";

const ExchangePoolsTable = ({ entity, exchangePools, onDelete }) => {
  const [currentTab, setCurrentTab] = useState("pools");
  const [pools, setPools] = useState([]);

  useEffect(() => {
    if (exchangePools && exchangePools.length > 0) {
      setPools(exchangePools);
    }
  }, [exchangePools]);

  const tabsFunctions = {
    pools: {
      func: function (data) {
        return data;
      },
      label: "Pools",
      color: "default",
      sortModel: [
        {
          field: "activeStake",
          sort: "desc",
        },
      ],
    },
  };

  const columns = [
    {
      field: "poolId",
      headerName: "Pool Id",
      cellClassName: "poolId-column--cell",
      width: 170,
      flex: 1,
    },
    {
      field: "activeStake",
      headerName: "Active Stake",
      cellClassName: "activeStake-column--cell",
      type: "number",
      headerAlign: "center",
      align: "center",
      width: 120,
      flex: 1,
      valueFormatter: (params) => {
        const convertedValue = Math.round(params.value / Math.pow(10, 6));
        return convertedValue.toLocaleString(undefined, {
          maximumFractionDigits: 0,
        });
      },
    },
    {
      field: "metadata",
      headerName: "Metadata",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const pool = params.row;
        return (
          <Tooltip
            title={
              <>
                <div>Name: {pool.name ?? "Unknown"}</div>
                <div>Ticker: {pool.ticker ?? "Unknown"}</div>
                <div>Description: {pool.description ?? "Unknown"}</div>
                <div>Homepage: {pool.homepage ?? "Unknown"}</div>
              </>
            }
            placement="top"
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: "1.0em",
                },
              },
            }}
          >
            <Icon>
              <InfoOutlinedIcon />
            </Icon>
          </Tooltip>
        );
      },
    },
    {
      field: "protocolVersion",
      headerName: "Protocol Version",
      cellClassName: "protoVersion-column--cell",
      headerAlign: "center",
      align: "center",
      width: 120,
      flex: 1,
      type: "number", // optional, but clarifies the type
      valueGetter: (params) => {
        const value = parseFloat(params.row.protocolVersion);
        return isNaN(value) ? 0 : value;
      },
      renderCell: (params) => {
        const pool = params.row;
        let displayVersion = "Unknown";
        if (pool.protocolVersion) {
          displayVersion = pool.protocolVersion;
        }
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
            <span>{displayVersion}</span>
            <Tooltip
              title={
                <>
                  <div>
                    Block Header Hash: {pool.blockHeaderHash ?? "Unknown"}
                  </div>
                  <div>
                    Block Header Epoch: {pool.blockHeaderEpoch ?? "Unknown"}
                  </div>
                  <div>
                    Block Header Time:{" "}
                    {pool.blockHeaderTime
                      ? `${new Date(
                          Number(pool.blockHeaderTime) * 1000
                        ).toLocaleString()} (${pool.blockHeaderTime})`
                      : "Unknown"}
                  </div>
                </>
              }
              placement="top"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: "1.0em",
                  },
                },
              }}
            >
              <Icon>
                <MoreHorizOutlinedIcon />
              </Icon>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type={"delete"}
              buttonText={"Delete"}
              onClick={() => onDelete(params.row.poolId)}
              sx={{
                padding: "5px 10px 5px 5px",
                fontSize: "0.675rem",
                width: "80px",
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <CustomTabsTable
      sxPaper={{
        height: "85%",
      }}
      sxTable={{
        m: 0,
      }}
      tabs={tabsFunctions}
      defaultValue={currentTab}
      columns={columns}
      name={"exchangePools"}
      data={pools}
      sortModel={tabsFunctions.pools.sortModel}
    />
  );
};

export default ExchangePoolsTable;
