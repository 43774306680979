import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import CustomButton from "../global/CustomButton";
import CustomTabsTable from "../global/CustomTabsTable";
import TruncateWords from "../global/TruncatedWords";

const EntityChannelsTable = ({ entity, onEdit, onDelete, filterModel }) => {
  const [channels, setChannels] = useState([]);
  const [currentTab, setCurrentTab] = useState("channels");
  const [filterModelValue, setFilterModelValue] = useState();

  useEffect(() => {
    setFilterModelValue(filterModel);
  }, [filterModel]);

  const handleFilterModelChange = (model) => {
    setFilterModelValue(model.filterModel);
  };

  const tabsFunctions = {
    channels: {
      func: function (data) {
        return data;
      },
      label: "Channels",
      color: "default",
    },
  };

  useEffect(() => {
    const transformedChannels = (entity?.baseEntity?.channels || []).map(
      (channel) => {
        return {
          ...channel,
          subscriptions: channel.subscriptions || [],
        };
      }
    );

    setChannels(transformedChannels);
  }, [entity]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      cellClassName: "name-column--cell",
      width: 170,
      flex: 1,
    },
    {
      field: "channelType",
      headerName: "Channel Type",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        const value = params.value || "";
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
    },
    {
      field: "chatId",
      headerName: "Chat ID",
      type: "string",
      headerAlign: "left",
      align: "left",
      flex: 1,
    },
    {
      field: "subscriptions",
      headerName: "Subscriptions",
      type: "string",
      width: "250",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <TruncateWords
            words={params.value.map((sub) => sub.name)}
            separator={", "}
            displayCount={3}
          />
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        const { id, channelType, chatId, name, slug } = params.row;
        const channelData = {
          id,
          channelType,
          chatId,
          name,
          slug,
          subscriptionIds: params.row.subscriptions.map((sub) => sub.id),
        };
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type={"edit"}
              buttonText={"Edit"}
              onClick={() => onEdit(channelData)}
              sx={{
                padding: "5px 10px 5px 5px",
                fontSize: "0.675rem",
                width: "80px",
              }}
            />
            <CustomButton
              type={"delete"}
              buttonText={"Delete"}
              onClick={() => onDelete(channelData)}
              sx={{
                padding: "5px 10px 5px 5px",
                fontSize: "0.675rem",
                width: "80px",
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <CustomTabsTable
      sxPaper={{
        height: "85%",
      }}
      sxTable={{
        m: 0,
      }}
      tabs={tabsFunctions}
      defaultValue={currentTab}
      columns={columns}
      name={"exchangeChannels"}
      data={channels}
      filterModel={filterModelValue}
      onFilterModelChange={handleFilterModelChange}
    />
  );
};

export default EntityChannelsTable;
