import SyncIcon from "@mui/icons-material/Sync";
import { Box, Container, Grid, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Header } from "../../components/Header";
import { useUserData } from "../../user";
import { useLocalState } from "../../utils/hooks/global/useLocalStorage";
import CustomButton from "../global/CustomButton";
import CustomModal from "../global/CustomModal";
import { Loading } from "../global/Loading";
import AddPoolForm from "./AddPoolForm";
import CreateEditChannelForm from "./CreateEditChannelForm";
import EditExchangeProfileForm from "./EditExchangeProfileForm";
import EntityChannelsTable from "./EntityChannelsTable";
import ExchangeDetails from "./ExchangeDetails";
import ExchangePoolsTable from "./ExchangePoolsTable";
import WalletDetails from "./WalletDetails";

const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const defaultChannel = {
  id: "",
  channelType: "",
  chatId: "",
  subscriptionIds: [],
  name: "",
  slug: "",
};

function Entity() {
  const { slug } = useParams();
  const [jwt] = useLocalState(null, "jwt");
  const [entity, setEntity] = useState();
  const [pools, setPools] = useState([]);
  const [countries, setCountries] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, setMode] = useState("create");
  const [exchangeInfo, setExchangeInfo] = useState();
  const [channel, setChannel] = useState(defaultChannel);
  const [requestTrigger, setRequestTrigger] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tabsValue, setTabsValue] = useState("profile");
  const [modalEditMode, setModalEditMode] = useState("integration");
  const userData = useUserData();
  const [isModalOpen, setModalOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const [tabValueQuery, setTabValueQuery] = useState(
    new URLSearchParams(location.search).get("tab")
  );
  const [filterValue, setFilterValue] = useState(
    new URLSearchParams(location.search).get("filter")
  );
  const [filterModel, setFilterModel] = useState({ items: [] });

  useEffect(() => {
    if (tabValueQuery) {
      switch (tabValueQuery) {
        case "channels":
          setTabsValue("channels");
          break;
        case "profile":
          setTabsValue("profile");
          break;
        case "pools":
          setTabsValue("pools");
          break;
        default:
          setTabsValue("profile");
          break;
      }
    }
  }, [tabValueQuery]);

  useEffect(() => {
    if (
      filterValue &&
      entity &&
      entity.baseEntity.channels &&
      entity.baseEntity.channels.length > 0
    ) {
      const [columnField, filterVal] = filterValue.split("=");
      if (columnField && filterVal) {
        setFilterModel({
          items: [
            {
              id: 1,
              field: decodeURIComponent(columnField),
              operator: "equals",
              value: decodeURIComponent(filterVal),
            },
          ],
        });
      }
      setFilterValue(null);
    }
  }, [filterValue, entity]);

  const handleSubmitForm = () => {
    setModalOpen(false);
    setRequestTrigger(!requestTrigger);
  };

  const handleAddChannelButtonClick = () => {
    setChannel(defaultChannel);
    setMode("create");
    setModalOpen(true);
  };

  const handleAddPoolButtonClick = () => {
    setMode("addPool");
    setModalOpen(true);
  };

  const handleSyncPoolsButtonClick = () => {
    setMode("syncPools");
    setModalOpen(true);
  };

  const handlePoolSubmit = () => {
    setModalOpen(false);
    setRequestTrigger(!requestTrigger);
  };

  const handleChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
    setTabsValue(newValue);
  };

  const handleEditProfile = (mode) => {
    setModalEditMode(mode);
    setModalOpen(true);
  };

  const handleEditChannel = (channel) => {
    setChannel(channel);
    setMode("update");
    setModalOpen(true);
  };

  useEffect(() => {
    if (entity) {
      fetch(`https://api.coingecko.com/api/v3/exchanges/${entity.slug}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setExchangeInfo(data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [entity]);

  const handleDeleteChannel = (channel) => {
    fetch(`${backendUrl}/channels/${channel.id}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Channel not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setRequestTrigger(!requestTrigger);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  const handleDeletePool = (poolId) => {
    fetch(`${backendUrl}/exchanges/pools/${poolId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + jwt,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error("Channel not found!");
          } else {
            throw new Error("There was an error processing your request.");
          }
        }
        return response.json();
      })
      .then((data) => {
        setRequestTrigger(!requestTrigger);
      })
      .catch((error) => {
        console.error("There was an error!", error.message);
      });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const [entityResponse, poolsResponse, countriesResponse] =
          await Promise.all([
            fetch(`${backendUrl}/entities/${slug}`, {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
            }),
            fetch(`${backendUrl}/exchanges/pools/${slug}`, {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
            }),
            fetch(`${backendUrl}/exchanges/countries/${slug}`, {
              method: "GET",
              credentials: "include",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + jwt,
              },
            }),
          ]);

        if (!entityResponse.ok) {
          if (entityResponse.status === 404) {
            throw new Error("Entity not found!");
          }
          throw new Error("There was an error processing your entity request.");
        }
        if (!poolsResponse.ok) {
          if (poolsResponse.status === 404) {
            throw new Error("Pools not found!");
          }
          throw new Error("There was an error processing your pools request.");
        }
        if (!countriesResponse.ok) {
          if (poolsResponse.status === 404) {
            throw new Error("Countries not found!");
          }
          throw new Error(
            "There was an error processing your countries request."
          );
        }

        const entityData = await entityResponse.json();
        const poolsData = await poolsResponse.json();
        const countriesData = await countriesResponse.json();

        setEntity(entityData);
        setPools(poolsData);
        setCountries(countriesData);
        setLoading(false);
      } catch (error) {
        console.error("There was an error!", error);
      }
    }
    fetchData();
  }, [jwt, slug, backendUrl, requestTrigger]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <Loading size={50} thickness={6} />
        </Box>
      ) : (
        <Container maxWidth={"xl"}>
          <Grid container>
            {entity && (
              <>
                <Grid item xs={6}>
                  <Header
                    title={
                      <>
                        <img
                          src={entity?.logo}
                          alt="logo"
                          style={{
                            width: "25px",
                            height: "25px",
                            marginRight: "10px",
                          }}
                        />
                        {entity?.name}
                      </>
                    }
                    subtitle={`${capitalizeFirstLetter(
                      entity?.baseEntity?.entityType
                    )} Profile`}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {userData.isAdmin && (
                    <>
                      {tabsValue === "channels" && (
                        <CustomButton
                          type={"create"}
                          buttonText={"Add Channel"}
                          onClick={handleAddChannelButtonClick}
                        />
                      )}
                      {tabsValue === "pools" && (
                        <Box
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            gap: "10px",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <CustomButton
                            color={"green"}
                            icon={SyncIcon}
                            buttonText={"Sync"}
                            onClick={handleSyncPoolsButtonClick}
                          />
                          <CustomButton
                            type={"create"}
                            buttonText={"Add Pool"}
                            onClick={handleAddPoolButtonClick}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </Grid>
                <Grid container spacing={3} mt={"5px"}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Tabs
                      value={tabsValue}
                      onChange={handleChange}
                      aria-label="secondary tabs example"
                      sx={{
                        paddingLeft: "20px",
                      }}
                      variant="scrollable"
                    >
                      <Tab disableRipple value="profile" label="Profile" />
                      <Tab disableRipple value="channels" label="Channels" />
                      <Tab disableRipple value="pools" label="Pools" />
                    </Tabs>
                  </Grid>
                  <Grid item xs={12}>
                    {entity && (
                      <>
                        {tabsValue === "profile" && (
                          <>
                            {entity.baseEntity.entityType === "exchange" && (
                              <ExchangeDetails
                                entityInit={entity}
                                poolsInit={pools}
                                countriesInit={countries}
                                onEdit={handleEditProfile}
                                exchangeInfo={exchangeInfo}
                              />
                            )}
                            {entity.baseEntity.entityType === "wallet" && (
                              <WalletDetails entity={entity} />
                            )}
                          </>
                        )}
                        {tabsValue === "channels" && (
                          <EntityChannelsTable
                            entity={entity}
                            onEdit={handleEditChannel}
                            onDelete={handleDeleteChannel}
                            filterModel={filterModel}
                          />
                        )}
                        {tabsValue === "pools" && (
                          <ExchangePoolsTable
                            entity={entity}
                            exchangePools={pools}
                            onDelete={handleDeletePool}
                            filterModel={filterModel}
                          />
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
          <CustomModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            sx={{
              width: "50vw",
              maxHeight: "90vh",
            }}
          >
            {tabsValue === "channels" && (
              <CreateEditChannelForm
                entity={entity}
                onSubmit={handleSubmitForm}
                channel={channel}
                mode={mode}
              />
            )}
            {tabsValue === "pools" && (
              <>
                <AddPoolForm
                  onSubmit={handlePoolSubmit}
                  exchangeSlug={entity.slug}
                  mode={mode}
                  onClose={() => setModalOpen(false)}
                />
              </>
            )}
            {tabsValue === "profile" &&
              entity.baseEntity.entityType === "exchange" && (
                <EditExchangeProfileForm
                  entity={entity}
                  onSubmit={handleSubmitForm}
                  mode={modalEditMode}
                />
              )}
          </CustomModal>
        </Container>
      )}
    </>
  );
}

export default Entity;
